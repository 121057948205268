import React, { useEffect, useState, useRef, useCallback } from "react";
import PageHeader from "../components/common/PageHeader";
import MetaData from "../helpers/MetaData";
import Accordion from "react-bootstrap/Accordion";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TbNumber1, TbNumber2 } from "react-icons/tb";
import axios from "axios";
import Select from "react-select";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import ReCAPTCHA from "react-google-recaptcha";
import GoToTop from "../helpers/GoToTop";
import { _setDefaults } from "gsap/gsap-core";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import moment from "moment";
import { MdClose } from "react-icons/md";

const expiry = process.env.REACT_APP_OTPEXPIRY;
let timer = null;

const LifeMembership = () => {
  const { user, token, setToken, donor } = useContext(AppContext);
  const relationValues = [{ name: "", age: "", relation: "" }];
  const recaptchaRef = useRef();
  const location = useLocation();
  const search = useLocation().search;
  const [searchParams, setSearchParams] = useSearchParams();
  // const lifeMembershipId = new URLSearchParams(search).get("lifeMembershipId");
  const [lifeMembershipId, setLifeMembershipId] = useState(searchParams.get("lifeMembershipId"));

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    control,
  } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: "familyMember",
  });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [top, setTop] = useState(0);
  const [isRetry, setIsRetry] = useState(false);
  const [isOtherRelation, setIsOtherRelation] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [isVerified, setIsverified] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpExp, setOtpExp] = useState(expiry);
  const [showresendlink, setShowResendLink] = useState(false);
  const [showTimer, setShowTimer] = useState(false);

  const navigate = useNavigate();

  const membershipRef = useCallback((node) => {
    if (node !== null && location.hash) {
      setTop(node.offsetTop);
    }
  });

  const relationOptions = [
    { value: "father", label: "Father" },
    { value: "mother", label: "Mother" },
    { value: "son", label: "Son" },
    { value: "daughter", label: "Daughter" },
    { value: "other", label: "Other" },
  ];
  const platformOptions = [
    { value: "facebook", label: "Facebook" },
    { value: "twitter", label: "Twitter" },
    { value: "instagram", label: "Instagram" },
    { value: "google", label: "Google" },
    { value: "temple-visit", label: "Temple Visit" },
    { value: "online-and-others", label: "Online and Others" },
    { value: "call-from-temple", label: "Call from Temple" },
  ];

  const fetchStateCity = async (pin) => {
    try {
      let url = `https://api.postalpincode.in/pincode/${pin}`;

      let config = {
        method: "GET",
        url,
      };
      const { data } = await axios(config);

      let cityVal = '';
      if (data[0].PostOffice.length > 1) {
        data[0].PostOffice.map(ct => {
          if (ct.Block !== 'NA') {
            return cityVal = ct.Block
          }
        })
      } else {
        cityVal = data[0].PostOffice[0].Block;
      }

      if (data[0].Status === "Success") {
        setValue("city", cityVal !== '' ? cityVal : data[0].PostOffice[0].District);
        setValue("state", data[0].PostOffice[0].State);
      } else {
        notification("warning", "Enter valid PIN code!");
      }
    } catch (err) {
      console.log(err);
    }
  };


  const checkValidPin = async (pin) => {
    try {
      let url = `https://api.postalpincode.in/pincode/${pin}`;
      let config = {
        method: "GET",
        url,
      };
      const { data } = await axios(config);

      if (data[0].Status === "Error") {
        notification("warning", "Enter valid PIN code!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchStates = async () => {
    try {
      var config = {
        method: "GET",
        url: "https://api.countrystatecity.in/v1/countries/IN/states",
        headers: {
          "X-CSCAPI-KEY": process.env.REACT_APP_CSCKEY,
        },
      };

      let res = await axios(config);

      let sortedArray = res.data.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });

      setStates(
        sortedArray.map((item) => ({ value: item.iso2, label: item.name }))
      );
      // console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      padding: "0 8px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
    }),
    input: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      margin: 0,
      padding: 0,
    }),
  };

  const fetchCities = async (state) => {
    try {
      var config = {
        method: "GET",
        url: `https://api.countrystatecity.in/v1/countries/IN/states/${state}/cities`,
        headers: {
          "X-CSCAPI-KEY": process.env.REACT_APP_CSCKEY,
        },
      };

      let res = await axios(config);

      setCities(
        res.data.map((item) => ({ value: item.name, label: item.name }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  function getTime(time) {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  }

  const handleSendOtp = async (phone) => {
    if (timer) {
      clearInterval(timer);
    }
    if (phone.length >= 10) {
      try {
        const res = await ApiService.sendOtp({ number: phone });
        notification("success", res.message);
        setShowTimer(true);
        timer = setInterval(() => {
          setOtpExp((prev) => prev - 1);
        }, 1000);
      } catch (err) {
        console.log(err);
        notification("error", err.response.data.message);
      }
    }
  };

  const handleVerifyOtp = async (otp) => {
    try {
      let otpData = {
        number: getValues("phone"),
        otp,
      };
      const res = await ApiService.loginUser(otpData);
      localStorage.setItem(`token`, res.token);
      setToken(res.token);
      notification("success", res.message);
      setOtp("");
      setIsverified(true);
      setShowOtp(false);

      madeDonation(getValues());
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }
  };

  const RelativeCreate = async (relative, main_icsid) => {
    //console.log(main_icsid)
    const user_ics_id = main_icsid;

    const responseee = await ApiService.getIcsData(relative.phone)


    // console.log(responseee)


    let rel_exists = false;

    if (responseee) {
      if (responseee && responseee.data && responseee.data.length > 0) {
        const chk = responseee.data.filter(dat => dat.name === relative.name).length;
        //console.log(chk)
        if (chk > 0) {
          rel_exists = true;
        }
      }

      //console.log(rel_exists)

      if (rel_exists == true) {

        const depend_Ics_Id = responseee.data[0].id

        let joinFamilyrelationship = {

          individual1ICSId: depend_Ics_Id,
          individual2ICSId: user_ics_id,
          relationName: relative.relation,
          relationCategory: 'FAMILY', //or 'ACQUAINTANCE',
          oper: 'add'

        }

        const responseeee = await ApiService.joinFamilyrelationshipIcs(joinFamilyrelationship)

        //console.log(responseeee.icsid);
        // if (responseeee) {
        //     //notification('success', 'Relative '+responseeee.data.message)
        // } else {
        //     // notification('error', 'Relative '+responseeee.data.message)
        // }



      } else {

        try {



          let createIndividual = {
            lname: relative.name,
            iname: '',
            gender: '',
            mobileNo: relative.phone,
            email: '',
            panNo: '',
            dob: moment(relative.dob).format('DD-MM-YYYY'),
            ma: '',
            address: relative.address,
            address2: '',
            address3: '',
            city: '',
            state: '',
            country: 'India',
            pincode: relative.pin,
            category: 'Relative',
            type: relative.relativeImpType,
            strictdq: 'no',
          }

          const response = await ApiService.createIndividualQuick(createIndividual)
          //console.log(response.icsid)

          if (response.icsid) {

            try {


              let joinFamilyrelationship = {

                individual1ICSId: response.icsid,
                individual2ICSId: user_ics_id,
                relationName: relative.relation,
                relationCategory: 'FAMILY', //or 'ACQUAINTANCE',
                oper: 'add'

              }

              const responsee = await ApiService.joinFamilyrelationshipIcs(joinFamilyrelationship)

              if (responsee) {
                // notification('success', 'Relative '+responsee.data.message)
              } else {
                //notification('error', 'Relative '+responsee.data.message)
              }

              //console.log(responsee)

            } catch (error) {
              console.error(error);
            }
          }

        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  const madeDonation = async (data) => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      data.token = token;
      data.userType = user ? 'user' : 'guest';
      if(donor && donor?.id) {
        data.donorId = donor.id;
      }
      const res = await ApiService.lifeMembership(data);

      const responseee = await ApiService.getIcsData(data.phone)
      // console.log(responseee)

      let main_icsid = null;



      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        currency: res.currency,
        amount: res.amount,
        name: process.env.REACT_APP_PROJECT_NAME,
        description: "Help Us to Help the Society",
        image: `${process.env.REACT_APP_S3_URL}/assets/img/logo.png`,
        order_id: res.id,
        handler: function (response) {
          navigate(`/thank-you/${res.lifeMembershipId}/life-membership`, {
            replace: true,
          });
          localStorage.setItem(`donation-${res.lifeMembershipId}`, res.lifeMembershipId);
          // navigate(`/thank-you/`, { replace: true })
          if (responseee) {
            if (responseee && responseee.data && responseee.data.length > 0) {
              // console.log(responseee.data.filter(dat => dat.name === data.firstname + ' ' + data.lastname)[0].id)
              main_icsid = responseee.data.filter(dat => dat.name === data.firstname + ' ' + data.lastname)[0].id;
            }
          }

          if (Array.isArray(data.familyMember)) {
            data.familyMember.map((relative) => (
              RelativeCreate(relative, main_icsid)
            ));
          } else {
            console.error("data.relatives is not an array.");
          }
        },
        prefill: {
          name: res.name ? res.name : "",
          email: res.email ? res.email : "",
          contact: res.phone ? res.phone : "",
        },
        notes: {
          type: "life-membership",
        },
        theme: {
          color: "#FFD370",
        },
        modal: {
          ondismiss: async function () {
            const cancelLifeMembership = await ApiService.cancelLifeMembership(
              res.id
            );
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", function (response) {
        navigate(
          `/payment-failed?id=${res.lifeMembershipId}&&type=lifeMembership`,
          { replace: true }
        );
      });

      // reset();
      // recaptchaRef.current.reset()
    } catch (error) {
      console.log(error);
      notification("error", "Please fill all the required fields.");
    }
  };

  const lifeMembershipSubmit = (data) => {
    // if (!isVerified) {
    //   setShowOtp(true);
    //   if (otp.length === 6) {
    //     handleVerifyOtp(otp);
    //   } else {
    //     handleSendOtp(data.phone);
    //   }
    //   return false;
    // }
    madeDonation(data);
  };

  const resendOtp = () => {
    setOtpExp(expiry);
    handleSendOtp(getValues("phone"));
    setShowResendLink(false);
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const loadValues = () => reset({ familyMember: relationValues });

  const retryLifeMembership = async () => {
    try {
      const res = await ApiService.retryLifeMembership(lifeMembershipId);
      // console.log(res)
      if (res) {
        fetchCities(res.state);
        let familyMembers = [];

        res.familyMembers.map((rl) => {
          let member = {};
          member.name = rl.name;
          member.age = rl.age;
          member.dob = rl.dob;
          member.relation = relationOptions.filter(
            (op) => op.value === rl.relation
          )[0];
          familyMembers.push(member);
        });

        reset({
          ...res,
          platform: platformOptions.filter(
            (pl) => pl.value === res.platformName
          )[0],
          state: res?.state ? states.filter((st) => st.value === res.state)[0]?.label : '',
          city: res.city,
          dob: moment(res.dob).format("YYYY-MM-DD"),
          familyMember: familyMembers,
        });
      } else {
        if (user) {
          reset({
            firstname: user?.name?.split(" ")[0],
            lastname:
              user?.name?.split(" ").length >= 2
                ? user?.name?.split(" ")[user?.name?.split(" ").length - 1]
                : "",
            address: user.address,
            pan: user.pan,
            email: user.email,
            phone: user.phone,
            pin: user.pin,
            familyMember: relationValues,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchStates();
    loadValues();
  }, []);

  useEffect(() => {
    if (user) {
      setIsverified(true);
    }
    if (user && !isRetry) {
      reset({
        firstname: user?.name?.split(" ")[0],
        lastname:
          user?.name?.split(" ").length >= 2
            ? user?.name?.split(" ")[user?.name?.split(" ").length - 1]
            : "",
        address: user.address,
        pan: user.pan,
        email: user.email,
        phone: user.phone,
        pin: user.pin,
        // dob: moment(user[0]?.dob).format("YYYY-MM-DD"),
        familyMember: relationValues,
      });
    }
  }, [user]);


  useEffect(() => {
    if (lifeMembershipId) {
      setIsRetry(true);
      retryLifeMembership();
    }
  }, [lifeMembershipId, states]);

  useEffect(() => {
    if (!user) {
      if (otpExp === 0) {
        setShowTimer(false);
        setShowResendLink(true);
        clearInterval(timer);
        setOtpExp(expiry);
      }
    }
  }, [otpExp, expiry, user]);

  return (
    <>
      <MetaData title="Life Membership Programme - ISKCON of Bhiwandi" />
      <PageHeader
        title="Life Membership Programme"
        imgSrc="/images/page-header/07.webp"
      />
      <section className="membership-program">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="heading">
                <h2 className="head-2">Become Life Patron Now</h2>
                <h2 className="head-1">
                  What is ISKCON Life Membership Program?
                </h2>
                <p className="lh-base">
                  The ISKCON Life Membership program was introduced in early
                  1970’s as an opportunity to become an integral part of the
                  ISKCON family by His Divine Grace A.C. Bhaktivedanta Swami
                  Prabhupad. He invited everyone across the world to take
                  advantage of residing in ISKCON temples all over the world for
                  a couple of days, attend spiritual programs and receive
                  Krishna conscious association. Presently more than 1 million
                  life patrons are part of ISKCON’s family.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="member-form-wrap py-0 d-none">
        <div className="container-fluid px-0">
          <div className="row m-0">
            <div className="col-lg-6 px-0">
              <div className="left-section-wrap">
                <div className="form-left-content">
                  <h2>How to become ISKCON Life Member?</h2>
                  <div className="form-info">
                    <div className="form-icon">
                      <TbNumber1 />
                    </div>
                    <div className="form_text">
                      <h4>ISKCON Life Patron Membership</h4>
                      <p>
                        Make a Contribution of Rs. 35,555/- for ISKCON Life
                        Patron Membership.
                      </p>
                    </div>
                  </div>
                  <div className="form-info">
                    <div className="form-icon">
                      <TbNumber2 />
                    </div>
                    <div className="form_text">
                      <h4>Fill The ISKCON Life Membership Form.</h4>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Temporibus molestias.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="life-membership-form" ref={membershipRef}>
        <div className="container">
          <form onSubmit={handleSubmit(lifeMembershipSubmit)}>
            <div className="heading">
              <h2 className="head-1">
                Donation for Life Patron Membership Programme
              </h2>
              <h5>Donation For:</h5>
              <p className="form-para">
                Donation for Life Patron Membership Programme
              </p>
              <p>
                Enrollment Donation of <span>Rs. 35,555/-</span>{" "}
              </p>
            </div>
            <div className="row">
              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">Your First Name</label>
                  <input
                    type="text"
                    maxLength={50}
                    placeholder="Your First Name"
                    {...register("firstname", {
                      required: "First name is required",
                      maxLength: {
                        value: 50,
                        message:
                          "First name can't exceeds more than 50 characters",
                      },
                    })}
                  />
                  {errors.firstname && (
                    <span className="text-danger">
                      {errors.firstname.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">Your Last Name</label>
                  <input
                    type="text"
                    placeholder="Your Last Name"
                    {...register("lastname", {
                      required: "Last name is required",
                      maxLength: {
                        value: 50,
                        message:
                          "First name can't exceeds more than 50 characters",
                      },
                    })}
                  />
                  {errors.lastname && (
                    <span className="text-danger">
                      {errors.lastname.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">Date of Birth</label>
                  <input
                    type="date"
                    max={moment(Date.now()).format("YYYY-MM-DD")}
                    placeholder="Date of Birth"
                    {...register("dob", {
                      required: "Date of birth is required",
                    })}
                  />
                  {errors.dob && (
                    <span className="text-danger">{errors.dob.message}</span>
                  )}
                </div>
              </div>
              {/* <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">If you have an initiated name</label>
                  <input
                    type="text"
                    placeholder="Enter Here"
                    {...register("initiatedName")}
                  />
                </div>
              </div> */}
              {/* <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">
                    Dedicate this donation to others name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Here"
                    {...register("dedicatorName")}
                  />
                </div>
              </div> */}
              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">Your E-mail ID</label>
                  <input
                    type="email"
                    placeholder="Enter a valid e-mail"
                    {...register("email", { required: "Email is required" })}
                  />
                  {errors.email && (
                    <span className="text-danger">{errors.email.message}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">Contact No</label>
                  <input
                    type="tel"
                    placeholder="Enter Mobile Number"
                    minLength={10}
                    maxLength={10}
                    {...register("phone", {
                      required: "Number is required",
                      maxLength: 10,
                      minLength: 10,
                      pattern: {
                        value: /^[6-9]\d*$/,
                        message: "Please provide valid phone number",
                      },
                    })}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {errors.phone && (
                    <span className="text-danger">{errors.phone.message}</span>
                  )}
                  {errors?.phone?.type === ("maxLength" || "minLength") && (
                    <span className="text-danger">
                      Please Enter valid number
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">PAN </label>
                  <input
                    type="text"
                    placeholder=" Enter PAN"
                    style={{ textTransform: "uppercase" }}
                    maxLength={10}
                    {...register("pan", {
                      pattern: {
                        value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                        message: "Please enter valid PAN",
                      },
                    })}
                  />
                  {errors.pan && (
                    <small className="text-danger error">
                      {errors.pan.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">Company Name</label>
                  <input
                    type="text"
                    placeholder="Enter Here"
                    {...register("companyName")}
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="form-part">
                  <label htmlFor="">Address</label>
                  <input
                    type="text"
                    placeholder="Address"
                    {...register("address")}
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-0 mb-lg-3">

                <div className="form-part">
                  <label htmlFor="">PIN</label>
                  <input
                    type="text"
                    maxLength={6}
                    {...register("pin", {
                      required: true,
                      pattern: {
                        value: /^\d+$/,
                        message: "PIN code must be number.",
                      },
                      onChange: (e) => {
                        setValue("state", "");
                        setValue("city", "");
                        if (e.target.value.length === 6) {
                          fetchStateCity(e.target.value);
                        }
                      },
                    })}
                    placeholder="PIN"
                  />
                  {errors.pin?.type === "required" && (
                    <small className="text-danger error">
                      This is required
                    </small>
                  )}
                  {errors.pin && (
                    <small className="text-danger error">
                      {errors.pin.message}
                    </small>
                  )}
                </div>

              </div>

              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">State</label>
                  <input
                    type="text"
                    placeholder="State"
                    {...register('state')}
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">City</label>
                  <input
                    type="text"
                    placeholder="City"
                    {...register('city')}
                  />
                </div>
              </div>

              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">Country</label>
                  <select placeholder="Enter Country" {...register("country")}>
                    <option value="india">India</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">Preacher (if any)</label>
                  <input
                    type="text"
                    placeholder="Preacher"
                    {...register("preacher")}
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="" className="hasSelect">
                    How did you come to know about us
                  </label>
                  <Controller
                    control={control}
                    name="platform"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        options={platformOptions}
                        closeMenuOnSelect={true}
                        value={value}
                        styles={customStyles}
                        name={name}
                        // isSearchable={false}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#f3b01b33",
                            primary: "#F3B01B",
                          },
                        })}
                        onChange={(val) => {
                          onChange(val);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-4 mb-0 mb-lg-3">
                <div className="form-part">
                  <label htmlFor="">Message</label>
                  <input
                    type="text"
                    placeholder="Enter Here"
                    {...register("message")}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <button
                  type="button"
                  className="custom-btn-cls box-hover ms-0 mb-4"
                  onClick={() => {
                    append({ name: "", age: "", relation: "" });
                  }}
                >
                  Add Family Details
                </button>
                {/* {fields?.length > 1 && (
                  <button
                    type="button"
                    className="custom-btn-cls box-hover ms-3 mb-4"
                    onClick={() => {
                      remove(fields.length - 1);
                    }}
                  >
                    remove member
                  </button>
                )} */}
              </div>

              {fields.map((item, i) => {
                return (
                  <div className="row m-0" key={i}>
                    <div className="col-lg-4">
                      <div className="form-part">
                        <label htmlFor="">Name</label>
                        <input
                          type="text"
                          placeholder="Enter Name"
                          {...register(`familyMember.${i}.name`)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="form-part">
                        <label htmlFor="relativeRelation">Relation with the relative</label>
                        <select className="" placeholder="Relation with the relative" {...register(`familyMember.${i}.relation`)}>
                          <option value="" selected>Relation with the relative</option>
                          <option value="Father">Father</option>
                          <option value="Mother">Mother</option>
                          <option value="Husband">Husband</option>
                          <option value="Spouse">Spouse</option>
                          <option value="Son">Son</option>

                          <option value="Daughter">Daughter</option>
                          <option value="Elder Brother">Elder Brother</option>
                          <option value="Younger Brother">Younger Brother</option>
                          <option value="Elder Sister">Elder Sister</option>
                          <option value="Younger Sister">Younger Sister</option>
                          <option value="Grand Father">Grand Father</option>
                          <option value="Grand Mother">Grand Mother</option>
                          <option value="Eldest Brother">Eldest Brother</option>
                          <option value="Youngest Brother">Youngest Brother</option>
                          <option value="Eldest Sister">Eldest Sister</option>
                          <option value="Youngest Sister">Youngest Sister</option>
                          <option value="Uncle (paternal – younger than father)">Uncle (paternal – younger than father)</option>
                          <option value="Uncle (paternal)">Uncle (paternal)</option>
                          <option value="Aunt (paternal – Wife of Chaachaa)">Aunt (paternal – Wife of Chaachaa)</option>
                          <option value="Aunt (Paternal)">Aunt (Paternal)</option>
                          <option value="Brother of Mother">Brother of Mother</option>
                          <option value="Younger brother of Mother">Younger brother of Mother</option>
                          <option value="Older Brother of Mother">Older Brother of Mother</option>
                          <option value="Wife of Older Brother of Mother">Wife of Older Brother of Mother</option>
                          <option value="Wife of Younger brother of Mother">Wife of Younger brother of Mother</option>
                          <option value="Grand Father (Father of Mother)">Grand Father (Father of Mother)</option>
                          <option value="Grand Mother (Mother of Mother)">Grand Mother (Mother of Mother)</option>
                          <option value="Son in Law (Husband of Daughter)">Son in Law (Husband of Daughter)</option>
                          <option value="Brother in Law (Husband of Older Sister)">Brother in Law (Husband of Older Sister)</option>
                          <option value="Brother in Law (Husband of Younger sister)">Brother in Law (Husband of Younger sister)</option>
                          <option value="Sister in Law (Wife of Older Brother)">Sister in Law (Wife of Older Brother)</option>
                          <option value="Sister in Law (Wife of Younger Brother)">Sister in Law (Wife of Younger Brother)</option>
                          <option value="Father Of Wife">Father Of Wife</option>
                          <option value="Mother of Wife">Mother of Wife</option>
                          <option value="Father of Son’s Wife">Father of Son’s Wife</option>
                          <option value="Mother of Son’s Wife">Mother of Son’s Wife</option>
                          <option value="Father of Daughter’s Husband">Father of Daughter’s Husband</option>
                          <option value="Mother of Daughter’s Husband">Mother of Daughter’s Husband</option>
                          <option value="Husband of Buwa">Husband of Buwa</option>
                          <option value="Daughter of Buwa">Daughter of Buwa</option>
                          <option value="Son of Buwa">Son of Buwa</option>
                          <option value="Sister’s Son">Sister’s Son</option>
                          <option value="Brother’s Son">Brother’s Son</option>
                          <option value="Sister’s Daughter">Sister’s Daughter</option>
                          <option value="Brother’s Daughter">Brother’s Daughter</option>
                          <option value="Step Mother">Step Mother</option>
                          <option value="Step Sister">Step Sister</option>
                          <option value="Step Brother">Step Brother</option>
                          <option value="Husband’s Other">Husband’s Other</option>
                          <option value="Step son">Step son</option>
                          <option value="Step Daughter">Step Daughter</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-lg-2 mb-0 mb-lg-3 ps-lg-3 ps-0 pe-0 pe-lg-0">
                      <div className="form-part">
                        <label htmlFor="">Age</label>
                        <input
                          type="number"
                          placeholder="Enter Age"
                          {...register(`familyMember.${i}.age`, {
                            pattern: {
                              value: /^\d+$/,
                              message: "Age must be number",
                            },
                          })}
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-4">
                      <div className="form-part">
                        <label htmlFor="">Date Of Birth</label>
                        <input
                          type="date"
                          placeholder="Date of Birth"
                          {...register(`familyMember.${i}.dob`)}
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-3 mb-3 pe-0 ps-lg-3 ps-0 pe-0 pe-lg-0">
                      <div className="form-part mb-0">
                        <label htmlFor="" className="hasSelect">
                          Relation
                        </label>
                        <Controller
                          control={control}
                          name={`familyMember.${i}.relation`}
                          render={({ field }) => (
                            <Select
                              {...field}
                              styles={customStyles}
                              options={relationOptions}
                              closeMenuOnSelect={true}
                              // isSearchable={false}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#f3b01b33",
                                  primary: "#F3B01B",
                                },
                              })}
                            />
                          )}
                        />
                      </div>
                    </div> */}

                    <div className="col-lg-3 col-md-6">
                      <div className="form-part">
                        <label htmlFor="relativeAddress">Address</label>
                        <input type="text" placeholder="Address" {...register(`familyMember.${i}.address`)}></input>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="form-part">
                        <label htmlFor="relativePin">PIN</label>
                        <input type="text" placeholder="PIN" minLength={6} maxLength={6} {...register(`familyMember.${i}.pin`)} onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }

                        }}
                          {...register(`familyMember.${i}.pin`, {
                            pattern: {
                              value: /^\d+$/,
                              message: 'PIN must be number.'
                            }, minLength: {
                              value: 6,
                              message: 'PIN must be minimum 10 digit'
                            }
                          })}></input>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="form-part">
                        <label htmlFor="relativePhone">Relative Phone Number</label>
                        <input type="text" maxLength={10} minLength={10} placeholder="Phone Number" {...register(`familyMember.${i}.phone`)}

                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}  {...register(`familyMember.${i}.phone`, {
                            required: false, pattern: {
                              value: /^\d+$/,
                              message: 'Mobile number must be number.'
                            }, minLength: {
                              value: 10,
                              message: 'Phone number must be minimum 10 digit'
                            }
                          })}



                        ></input>
                        {errors.relativePhone?.type === 'required' && <small className="text-danger"> Number is required </small>}
                        {errors.relativePhone && <span className="error">{errors.relativePhone.message}</span>}
                      </div>
                    </div>
                    <div className="col-lg-1 mb-4 mb-lg-0 ps-0 ps-lg-3">
                      <button
                        type="button"
                        onClick={() => remove(i)}
                        className="form-close-btn"
                      >
                        <MdClose />
                      </button>
                    </div>
                  </div>
                );
              })}


              <div className="col-12">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                  ref={recaptchaRef}
                  size="invisible"
                />
              </div>
              <div className="col-12">
                <button type="submit" className="custom-btn-cls box-hover ms-0">
                  donate
                </button>
                {showOtp && (
                  <>
                    <div className="form-part d-inline-block my-2">
                      <input
                        type="text"
                        placeholder="Enter OTP"
                        maxLength={6}
                        minLength={6}
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {!showresendlink && showTimer && (
                        <span
                          style={{
                            color: "#9F1D21",
                            display: "inline-block",
                            marginLeft: "10px",
                          }}
                        >
                          {getTime(otpExp)}s
                        </span>
                      )}
                    </div>
                    <>
                      {showresendlink && (
                        <button
                          className="custom-btn-cls box-hover"
                          onClick={resendOtp}
                        >
                          RESEND
                        </button>
                      )}
                    </>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </section>
      <section className="upcoming-immersions right-align bg-white">
        <div className="container">
          <div className="heading">
            <h2 className="head-1 text-uppercase">
              What are benefits of ISKCON Life Membership Program?
            </h2>
          </div>
          <div className="row pt-sm-4 pt-1 mt-4 mt-lg-0">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <Accordion className="accordion-wrap">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="accordion text-uppercase">
                    A. ISKCON Guest House Accommodation
                  </Accordion.Header>
                  <Accordion.Body className="accordion-panel">
                    <ul className="list-unstyled">
                      <li>
                        You are welcome to stay in any ISKCON center all over
                        the world for three days in a year. Maintenance charges
                        will be applied in ISKCON centers. All accommodation is
                        subject to availability and only with confirmed advance
                        reservation. This applies to accommodation in a single
                        room of the patron, spouse and children below 18 years
                        of age, and dependent parents above 60 years of age.
                        Rules may vary in different countries.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="accordion text-uppercase">
                    B. Srila Prabhupada Books
                  </Accordion.Header>
                  <Accordion.Body className="accordion-panel">
                    <ul className="list-unstyled">
                      <li>
                        A set of books by His Divine Grace A.C. Bhaktivedanta
                        Swami Prabhupad, available in all major Indian
                        Languages, is provided to the Life Patron Member. In
                        addition, a photo frame is provided.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="accordion text-uppercase">
                    C. Spiritual Benefits
                  </Accordion.Header>
                  <Accordion.Body className="accordion-panel">
                    <ul className="list-unstyled">
                      <li className="bullet">
                        Special Birthday Pooja for the Life Member followed by
                        Prasadam for his/her family.
                      </li>
                      <li className="bullet">
                        Prasadam is served every Sunday afternoon in the main
                        temple hall for life member and his/her family.
                      </li>
                      <li className="bullet">
                        Kirtan/Pravachan program at your home on special
                        occasions.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-lg-6">
              <Accordion className="accordion-wrap">
                <Accordion.Item eventKey="4">
                  <Accordion.Header className="accordion text-uppercase">
                    D. Other Privileges
                  </Accordion.Header>
                  <Accordion.Body className="accordion-panel">
                    <ul className="list-unstyled">
                      <li className="bullet">
                        Special Invitation passes will be sent for festivals
                        like- Janmashtami and Ram Navmi, etc.
                      </li>
                      <li className="bullet">Spiritual Counselling</li>
                      <li className="bullet">
                        Prasadam is couriered on birthday of relatives of Life
                        Member and other anniversaries or special occasions.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header className="accordion text-uppercase">
                    E. Tax Benefits
                  </Accordion.Header>
                  <Accordion.Body className="accordion-panel">
                    <ul className="list-unstyled">
                      <li>
                        Your donation is tax exempt under section 80G of the
                        Income Tax Act, 1961 (India) .
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <section className="mission lifememberPage">
        <div className="container">
          <div className="mission-wrap">
            <div className="row align-items-center">
              <div className="col-lg-6 mt-lg-0 mt-4 order-1 order-lg-0">
                <div className="heading">
                  <h2 className="head-1 text-lg-start text-uppercase">
                    Explore ISKCON Centres around the Globe
                  </h2>
                  <p className="mt-4">
                    While as a life member you will have the opportunity to feel
                    like home at any ISKCON center at the same time your
                    donation will help the temple propagate Krishna
                    consciousness all over the globe. Apart from serving people
                    with the message of spiritual knowledge and transcendental
                    happiness, ISKCON also participates in various social work
                    like <Link to="/food-for-life">Food for Life</Link> Program
                    serving lakhs of free meals daily. We invite you to stand
                    beside us as we march ahead in this Hare Krishna movement.
                  </p>
                  <div className="mt-4 text-lg-start text-center order-0 order-lg-1">
                    <a
                      href="https://centres.iskcon.org/"
                      target="_blank"
                      className="text-uppercase custom-btn-cls box-hover ms-0"
                    >
                      Explore ISKCON Centres
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-lg-5">
                <figure>
                  <img src="/images/about-us/krishna.webp" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GoToTop top={top} />
    </>
  );
};

export default LifeMembership;
