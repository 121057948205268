import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import PageHeader from "../components/common/PageHeader";
import AppContext from "../context/AppContext";
import MetaData from "../helpers/MetaData";
import { useForm } from "react-hook-form";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

const DmsUpdateProfile = () => {
  const {fetchDonor} = useContext(AppContext)
  const { id } = useParams();
  const sectionRef = useRef(null);
  const [donorData, setDonorData] = useState(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const navigate = useNavigate();

  const icsProfileUpdate = async (data) => {
    try {
      let profileUpdateData = {
        icsid: donorData?.ics_id,
        name: data.name,
        number : donorData?.phone,
        email : data.email,
        panNo : data.pan,
        dob : '',
        ma : '',
        addressLine1 : data.address,
        addressLine2 : data.streetName,
        addressLine3 : data.areaName,
        city : data.city,
        state : data.state,
        country : 'India',
        pincode : data.pin,
        followupCategory : data.comm,
        followupDescription : 'followupDescription',
        mode: 'STP',       
    }

    const response = await ApiService.icsUpdate(profileUpdateData);

    if(response) {
      navigate('/dms/dashboard');
    }

    } catch (error) {
      console.error(error);
    }
  }

  const handleProfileUpdate = async (data) => {
    try {
      const response = await ApiService.updateDonor({ id, data });
      notification("success", response.message);
      fetchDonorById();
      icsProfileUpdate(data);
      fetchDonor();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDonorById = async () => {
    try {
      const response = await ApiService.fetchDonorById(id);
      if (response.donor) {
        setDonorData(response.donor);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (donorData) {
      reset({
        name: donorData.legal_name,
        phone: donorData.phone,
        email: donorData.email,
        pan: donorData.pan,
        address: donorData?.addresses[0]?.address_line_1,
        pin: donorData?.addresses[0]?.pincode,
        state: donorData?.addresses[0]?.state,
        city: donorData?.addresses[0]?.city,
        streetName: donorData?.addresses[0]?.address_line_2,
        areaName: donorData?.addresses[0]?.landmark,
      });
    }
  }, [donorData]);

  useEffect(() => {
    fetchDonorById();
  }, []);

  return (
    <>
      <MetaData title="Dashboard - ISKCON of Bhiwandi" />
      <PageHeader title="User profile" imgSrc="/images/page-header/24.jpg" />

      <section className="donation-form-sec">
        <div className="container">
          <div className="row d-flex justify-content-center mb-5">
            <div className="col-md-10">
              <div className="heading">
                <h2 className="head-1 text-uppercase">Update Profile</h2>
                <p className="b-line">
                  If you would like to make a donation towards a particular area
                  of activity, please select an option from below. ISKCON relies
                  entirely on voluntary donations and so every donation counts.
                  Please note that donation is processed on a secure site.
                </p>
              </div>
            </div>
            <div ref={sectionRef} className="form-wrap my-5">
              <form onSubmit={handleSubmit(handleProfileUpdate)}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Name</label>
                      <input
                        type="text"
                        disabled={true}
                        placeholder="Name"
                        {...register("name")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Mobile Number</label>
                      <input
                        type="tel"
                        placeholder="Mobile Number"
                        disabled={true}
                        {...register("phone")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Email</label>
                      <input
                        type="email"
                        placeholder="Email"
                        {...register("email")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">PAN</label>
                      <input
                        type="text"
                        style={{ textTransform: "uppercase" }}
                        maxLength={10}
                        // disabled={user_pan ? true : false}
                        placeholder="PAN"
                        {...register("pan", {
                          pattern: {
                            value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                            message: "Please enter valid PAN",
                          },
                        })}
                      />
                      {/* {user_pan ? 
                      <small 
                          className="donation-link"
                         style={{color:'#F3B01B'}}
                        >
                          * Note :- Please contact to support team for changes in the PAN number on   support@iskconbhiwandi.org
                        </small>
                        : ''} */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Street Name</label>
                      <input
                        type="text"
                        placeholder="Street Name"
                        {...register("streetName")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Area Name</label>
                      <input
                        type="text"
                        placeholder="Area Name"
                        {...register("areaName")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">Address</label>
                      <input
                        type="text"
                        placeholder="Address"
                        {...register("address", {
                          required: true,
                          minLength: {
                            value: 10,
                            message:
                              "House / Building No. / Street Name must be minimum 10 characters",
                          },
                        })}
                      />
                      {errors.address?.type === "required" && (
                        <small className="text-danger error">
                          This is requiredd
                        </small>
                      )}
                      {errors.address && (
                        <small className="error">
                          {errors.address.message}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">PIN</label>
                      <input
                        type="text"
                        placeholder="PIN"
                        minLength={6}
                        maxLength={6}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register("pin", {
                          pattern: {
                            value: /^\d+$/,
                            message: "PIN code must be number.",
                          },
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">State</label>
                      <input
                        type="text"
                        placeholder="State"
                        {...register("state")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-part">
                      <label htmlFor="">City</label>
                      <input
                        type="text"
                        placeholder="City"
                        {...register("city")}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <button className="box-hover custom-btn-cls ms-0 mt-md-0 mt-3 donate-now-clicked-form">
                      submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DmsUpdateProfile;
