import React, { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../components/common/PageHeader";
import AppContext from "../context/AppContext";
import MetaData from "../helpers/MetaData";
import { HiOutlineDownload } from "react-icons/hi";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import { useState } from "react";
import moment from "moment";
import fileDownload from "js-file-download";
import GoToTop from "../helpers/GoToTop";
import axios from "axios";
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import Tooltip from '@mui/material/Tooltip';

const LanternTrack = () => {

    const navigate = useNavigate();

   
    const [donations, setDonations] = useState(null);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(0);
    const [failed, setFailed] = useState(0);
    const [pending, setPending] = useState(0);

    const logOut = () => {
        localStorage.removeItem(`lantern_token`);
        navigate('/lantern_panel')
    };

    const fetchDonation = async () => {
        try {
            let {donations, total, totalPages, success, failed, pending} = await ApiService.fetchDonationData(page);
            // console.log(res.donations)
            setDonations(donations);
            setSuccess(success)
            setFailed(failed)
            setPending(pending)
            setTotal(total)
            setTotalPages(totalPages)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchDonation();
    }, [page]);

    useEffect(() => {
        if(localStorage.getItem(`lantern_token`)==null){
            logOut();
        }
    }, [])

    return (
        <>
            <GoToTop />
            <MetaData title="Dashboard - ISKCON of Bhiwandi" />


            <section className="dashboard-table">
                <div className="container">

                    <div className="donation-table">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <h5 className='table-title text-success'>Success :- {success} </h5>
                            </div>
                            <div className="col-6">
                                <div className="text-end">
                                <button
                                    className="custom-btn-cls box-hover text-uppercase"
                                    onClick={logOut}
                                >
                                    Log out
                                </button>
                                </div>
                            </div>
                        </div>


                        <div className="table-heading py-4 d-none d-lg-block">
                            <div className="row justify-content-evenly align-items-center">

                                <div className="col-lg-3">
                                    <h5>Date</h5>
                                </div>
                               
                                <div className="col-lg-3">
                                    <h5>Campaign</h5>
                                </div>
                                <div className="col-lg-2">
                                    <h5>Slug</h5>
                                </div>
                                <div className="col-lg-2">
                                    <h5>Amount</h5>
                                </div>

                                <div className="col-lg-2">
                                    <h5>Status</h5>
                                </div>

                            </div>
                        </div>
                        <div className="table-content pt-2 pt-lg-0">
                            <div className="row">
               {donations?.map((donation, i) => (

                                <div className="col-lg-12 col-md-6 " key={i}>
                                    <div className="table-row py-4">
                                        <div className="row align-items-center">

                                            <div className="col-lg-3 ">
                                                <div className="form-field ">
                                                    <h5 className="form-title">Date :</h5>
                                                    <p id="donation-date">

                                                    {moment(donation?.createdAt).format('DD-MM-YYYY')}
                                                    </p>
                                                </div>
                                            </div>
                                           
                                            <div className="col-lg-3">
                                                <div className="form-field">
                                                    <h5 className="form-title">Campaign :</h5>
                                                    <p id="donation-name">

                                                        {donation?.type}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-field">
                                                    <h5 className="form-title">Slug :</h5>
                                                    <p id="donation-amount">  {donation?.trackingCampaign.slug}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-field">
                                                    <h5 className="form-title">Amount :</h5>
                                                    <p id="donation-amount">  {donation?.amount}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-2">
                                                <div className="form-field">
                                                    <h5 className="form-title">Status :</h5>
                                                    <p id="donation-status" className={`text-uppercase success text-bold`}>
                                                        {donation?.paymentStatus === 'success' ? <span className="text-success">Success</span>
                                                        : donation?.paymentStatus === 'pending' ? <span className="text-secondary">Pending</span>
                                                        : <span className="text-danger">Failed</span> 
                                                    }
                                                    </p>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                               ) )}

                            </div>
                        </div>

                        <nav className={total < perPage ? 'd-none' : ''}>
                            <ul className="pagination">
                                <li className={`page-item ${page <= 1 ? 'disabled' : ''}`}>
                                    <button
                                        type="button"
                                        className="page-link arrow"
                                        onClick={() => setPage(page - 1)}
                                    >
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 256 512"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                                        </svg>
                                    </button>
                                </li>
                                <li className={`page-item ${page <= 1 ? 'd-none' : ''}`}>
                                    <a className="page-link" 
                                    onClick={() => setPage(page - 1)}
                                    >
                                        {page - 1}
                                    </a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link active">{page}</a>
                                </li>
                                <li className={`page-item ${page === totalPages ? "d-none" : ""}`}>
                                    <a className="page-link" 
                                    onClick={() => setPage(page + 1)}
                                    >
                                        {page + 1}
                                    </a>
                                </li>
                                <li
                                    className={`page-item ${page === totalPages ? "disabled" : ""}`}
                                >
                                    <button
                                        type="button"
                                        className="page-link arrow"
                                       onClick={() => setPage(page + 1)}
                                    >
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 256 512"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>





                </div>
            </section>
        </>
    );
}

export default LanternTrack;