import PageHeader from "../components/common/PageHeader";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import { AiOutlineCalendar } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { useCallback, useState, useRef } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import ReCAPTCHA from "react-google-recaptcha";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import { useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const DmsJoinFamily = () => {
  const navigate = useNavigate();
  const { donor } = useContext(AppContext);

  const [top, setTop] = useState(0);
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    control,
    getValues,
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "relatives",
  });

  const recaptchaRef = useRef();
  const [joinFamily, setJoinFamily] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const [donorData, setDonorData] = useState(null);
  const [isSpouseExists, setIsSpouseExists] = useState(false);

  const formRef = useCallback((node) => {
    setTop(node?.offsetTop);
  });

  const joinFamilySubmit = async (data) => {
    try {
      const response = await ApiService.updateRelatives({data, donorId: donor.id});
      notification("success", response?.message);
      fetchRelatives(donor.id);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRelatives = async (id) => {
    try {
      const response = await ApiService.fetchRelatives(id);
      setDonorData(response.donor)
      setJoinFamily(response.relatives);
      setProfiles(response.profiles);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {

    if(donor) {
        fetchRelatives(donor.id);
    }

  },[donor]);

  useEffect(() => {
    if(donorData) {
        // let spouse = joinFamily?.relations?.find((item) => item?.DonorRelation?.relation === "Spouse");
        let spouse = joinFamily?.find((item) => item?.relation === "Spouse");
        if(!spouse) {
          spouse = profiles?.find((item) => item.relation === "Spouse");
        }

        if(spouse && spouse?.phone) {
          setIsSpouseExists(true);
        }

        const relatives = [];

        joinFamily?.filter((item) => item?.relation !== "Spouse").map((relative) => relatives.push({
              relativeName: relative?.legal_name,
              relativeRelation: relative?.relation,
              ...(relative?.dod && { relativeImpDate: relative?.dob, relativeImpType: 'death-anniversary' }),
              ...(relative?.doa && { relativeImpDate: relative?.doa, relativeImpType: 'marriage-anniversary'}),
              ...(relative?.dob && { relativeImpDate: relative?.dob, relativeImpType: 'birthday'}),
              relativeAddress: relative?.default_address?.address_line_1,
              relativePin: relative?.default_address?.pincode,
              relativeId: relative.id,
              relativePhone: relative?.phone
        }));

        // profiles?.filter((item) => item.relation !== "Spouse").map((profile) => relatives.push({
        //   relativeName: profile?.legal_name,
        //   relativeRelation: profile?.relation,
        //   relativePhone: profile?.phone,
        //   ...(profile?.dod && { relativeImpDate: profile?.dob, relativeImpType: 'death-anniversary' }),
        //   ...(profile?.doa && { relativeImpDate: profile?.doa, relativeImpType: 'marriage-anniversary'}),
        //   ...(profile?.dob && { relativeImpDate: profile?.dob, relativeImpType: 'birthday'}),
        //   relativeAddress: profile?.addresses[0]?.address_line_1,
        //   relativePin: profile?.addresses[0]?.pincode,
        //   relativeId: profile.id,
        // }));

        profiles?.filter((item) => item.relation !== "Spouse").map((profile) => {

          if(profile?.relation) {
            relatives.push({
              relativeName: profile?.legal_name,
              relativeRelation: profile?.relation,
              relativePhone: profile?.phone,
              ...(profile?.dod && { relativeImpDate: profile?.dob, relativeImpType: 'death-anniversary' }),
              ...(profile?.doa && { relativeImpDate: profile?.doa, relativeImpType: 'marriage-anniversary'}),
              ...(profile?.dob && { relativeImpDate: profile?.dob, relativeImpType: 'birthday'}),
              relativeAddress: profile?.addresses[0]?.address_line_1,
              relativePin: profile?.addresses[0]?.pincode,
              relativeId: profile.id,
            })
          }
        });

        if(relatives) relatives?.sort((a,b) => a.relativeId - b.relativeId);

        reset({
            name: donorData.legal_name,
            dob: donorData.dob,
            number: donorData.phone,
            marriageAnniversary: donorData.doa,
            ...(spouse && {
              spouseName: spouse?.legal_name,
              spouseDob: spouse?.dob,
              spouseNumber: spouse?.phone,
              spouseId: spouse.id
            }),
            relatives
        });
    }
  },[donorData])

  return (
    <>
      <MetaData title="Join Family - ISKCON of Bhiwandi" />
      <PageHeader
        title="JOIN THE FAMILY OF LORD KRISHNA"
        imgSrc="/images/page-header/24.jpg"
      />

      <section className="join-family-sec" ref={formRef}>
        <div className="container">
          <div className="row d-flex justify-content-center mb-5">
            <div className="col-md-10">
              <div className="heading">
                <h2 className="head-1">Join the Family of Lord Krishna !</h2>
                <p className="b-line">
                  Celebrate your memorable days like Birthday, Marriage
                  Anniversary, Child's Birthday and many more occasions with us.
                  We will offer collective prayers to the Lord on such special
                  occasions for you and your loved ones. Please fill in the
                  details to serve you better
                </p>
              </div>
            </div>
          </div>
          <div className="join-family-form">
            <form onSubmit={handleSubmit(joinFamilySubmit)}>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="name">Your Name</label>
                    <input
                      type="text"
                      placeholder="Name"
                      maxLength={50}
                      {...register("name", {
                        maxLength: {
                          value: 50,
                          message: "Name can't exceeds more than 50 characters",
                        },
                      })}
                    ></input>
                    {errors.name?.type === "required" && (
                      <small className="text-danger">
                        {" "}
                        First name is required{" "}
                      </small>
                    )}
                    {errors.name?.message && (
                      <small className="text-danger error">
                        {errors.name?.message}
                      </small>
                    )}
                  </div>
                </div>
               
                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="dob">Your Date of Birth</label>
                    <div className="input-date">
                      <input
                        type="date"
                        max={moment(Date.now()).format("YYYY-MM-DD")}
                        placeholder="Date Of Birth"
                        {...register("dob")}
                      ></input>
                      <AiOutlineCalendar />
                      {errors.dob?.type === "required" && (
                        <small className="text-danger">
                          {" "}
                          Date of Birth is required{" "}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="number">Your Contact Number</label>
                    <input
                      type="tel"
                      minLength={10}
                      maxLength={10}
                      placeholder="Phone Number"
                      disabled={donor ? true : false}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...register("number", {
                        required: false,
                        pattern: {
                          value: /^[6-9]\d*$/,
                          message: "Please provide valid phone number",
                        },
                        minLength: {
                          value: 10,
                          message: "Phone number must be minimum 10 digit",
                        },
                      })}
                    ></input>
                    {errors.number?.type === "required" && (
                      <small className="text-danger">
                        {" "}
                        Number is required{" "}
                      </small>
                    )}
                    {errors.number && (
                      <span className="error">{errors.number.message}</span>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="spouseName">Spouse Name </label>
                    <input
                      type="text"
                      placeholder="Spouse Name"
                      {...register("spouseName")}
                    ></input>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="spouseDob">Spouse Date of Birth</label>
                    <div className="input-date">
                      <input
                        type="date"
                        max={moment(Date.now()).format("DD-MM-YYYY")}
                        placeholder="date"
                        {...register("spouseDob")}
                      ></input>
                      <AiOutlineCalendar />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="spouseNumber">Spouse Number</label>
                    <input
                      type="text"
                      minLength={10}
                      maxLength={10}
                      placeholder="Phone Number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      disabled={isSpouseExists}
                      {...register("spouseNumber", {
                        required: false,
                        pattern: {
                          value: /^\d+$/,
                          message: "Mobile number must be number.",
                        },
                        minLength: {
                          value: 10,
                          message: "Phone number must be minimum 10 digit",
                        },
                      })}
                    ></input>
                    {errors.spouseNumber?.type === "required" && (
                      <small className="text-danger">
                        {" "}
                        Number is required{" "}
                      </small>
                    )}
                    {errors.spouseNumber && (
                      <span className="error">
                        {errors.spouseNumber.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="marriageAnniversary">
                      Marriage Anniversary
                    </label>
                    <div className="input-date">
                      <input
                        type="date"
                        max={moment(Date.now()).format("YYYY-MM-DD")}
                        placeholder="date"
                        {...register("marriageAnniversary")}
                      ></input>
                      <AiOutlineCalendar />
                    </div>
                  </div>
                </div>
             
                {fields.map((item, index) => {
                  return (
                    <div className="row" key={item.id}>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-part">
                          <label htmlFor="relativeName">
                            Name of the Relative
                          </label>
                          <input
                            type="text"
                            placeholder="Name of the relative"
                            {...register(`relatives.${index}.relativeName`)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="form-part">
                          <label htmlFor="relativeRelation">
                            Relation with the relative
                          </label>
                          <select
                            className="w-100"
                            placeholder="Relation with the relative"
                            {...register(`relatives.${index}.relativeRelation`)}
                          >
                            <option value="">
                              Relation with the relative
                            </option>
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                            <option value="Elder Brother">Elder Brother</option>
                            <option value="Younger Brother">
                              Younger Brother
                            </option>
                            <option value="Elder Sister">Elder Sister</option>
                            <option value="Younger Sister">
                              Younger Sister
                            </option>
                            <option value="Grand Father">Grand Father</option>
                            <option value="Grand Mother">Grand Mother</option>
                            <option value="Eldest Brother">
                              Eldest Brother
                            </option>
                            <option value="Youngest Brother">
                              Youngest Brother
                            </option>
                            <option value="Eldest Sister">Eldest Sister</option>
                            <option value="Youngest Sister">
                              Youngest Sister
                            </option>
                            <option value="Uncle (paternal – younger than father)">
                              Uncle (paternal – younger than father)
                            </option>
                            <option value="Uncle (paternal)">
                              Uncle (paternal)
                            </option>
                            <option value="Aunt (paternal – Wife of Chaachaa)">
                              Aunt (paternal – Wife of Chaachaa)
                            </option>
                            <option value="Aunt (Paternal)">
                              Aunt (Paternal)
                            </option>
                            <option value="Brother of Mother">
                              Brother of Mother
                            </option>
                            <option value="Younger brother of Mother">
                              Younger brother of Mother
                            </option>
                            <option value="Older Brother of Mother">
                              Older Brother of Mother
                            </option>
                            <option value="Wife of Older Brother of Mother">
                              Wife of Older Brother of Mother
                            </option>
                            <option value="Wife of Younger brother of Mother">
                              Wife of Younger brother of Mother
                            </option>
                            <option value="Grand Father (Father of Mother)">
                              Grand Father (Father of Mother)
                            </option>
                            <option value="Grand Mother (Mother of Mother)">
                              Grand Mother (Mother of Mother)
                            </option>
                            <option value="Son in Law (Husband of Daughter)">
                              Son in Law (Husband of Daughter)
                            </option>
                            <option value="Brother in Law (Husband of Older Sister)">
                              Brother in Law (Husband of Older Sister)
                            </option>
                            <option value="Brother in Law (Husband of Younger sister)">
                              Brother in Law (Husband of Younger sister)
                            </option>
                            <option value="Sister in Law (Wife of Older Brother)">
                              Sister in Law (Wife of Older Brother)
                            </option>
                            <option value="Sister in Law (Wife of Younger Brother)">
                              Sister in Law (Wife of Younger Brother)
                            </option>
                            <option value="Father Of Wife">
                              Father Of Wife
                            </option>
                            <option value="Mother of Wife">
                              Mother of Wife
                            </option>
                            <option value="Father of Son’s Wife">
                              Father of Son’s Wife
                            </option>
                            <option value="Mother of Son’s Wife">
                              Mother of Son’s Wife
                            </option>
                            <option value="Father of Daughter’s Husband">
                              Father of Daughter’s Husband
                            </option>
                            <option value="Mother of Daughter’s Husband">
                              Mother of Daughter’s Husband
                            </option>
                            <option value="Husband of Buwa">
                              Husband of Buwa
                            </option>
                            <option value="Daughter of Buwa">
                              Daughter of Buwa
                            </option>
                            <option value="Son of Buwa">Son of Buwa</option>
                            <option value="Sister’s Son">Sister’s Son</option>
                            <option value="Brother’s Son">Brother’s Son</option>
                            <option value="Sister’s Daughter">
                              Sister’s Daughter
                            </option>
                            <option value="Brother’s Daughter">
                              Brother’s Daughter
                            </option>
                            <option value="Step Mother">Step Mother</option>
                            <option value="Step Sister">Step Sister</option>
                            <option value="Step Brother">Step Brother</option>
                            <option value="Husband’s Other">
                              Husband’s Other
                            </option>
                            <option value="Step son">Step son</option>
                            <option value="Step Daughter">Step Daughter</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-part">
                          <label htmlFor="relativeImpDate">
                            Birth/Marriage/Death Anniversary
                          </label>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="input-date">
                                <input
                                  type="date"
                                  max={moment(Date.now()).format("DD-MM-YYYY")}
                                  placeholder="Select Date"
                                  {...register(
                                    `relatives.${index}.relativeImpDate`
                                  )}
                                ></input>
                                <AiOutlineCalendar />
                              </div>
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0">
                              <select
                                className="w-100"
                                {...register(
                                  `relatives.${index}.relativeImpType`
                                )}
                              >
                                <option value="birthday">Birthday</option>
                                <option value="marriage-anniversary">
                                  Marriage Anniversary
                                </option>
                                <option value="death-anniversary">
                                  Death Anniversary
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="form-part">
                          <label htmlFor="relativeAddress">Address</label>
                          <input
                            type="text"
                            placeholder="Address"
                            maxLength={250}
                            {...register(`relatives.${index}.relativeAddress`)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="form-part">
                          <label htmlFor="relativePin">PIN</label>
                          <input
                            type="text"
                            placeholder="PIN"
                            minLength={6}
                            maxLength={6}
                            {...register(`relatives.${index}.relativePin`)}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register(`relatives.${index}.relativePin`, {
                              pattern: {
                                value: /^\d+$/,
                                message: "PIN must be number.",
                              },
                              minLength: {
                                value: 6,
                                message: "PIN must be minimum 10 digit",
                              },
                            })}
                          ></input>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="form-part">
                          <label htmlFor="relativePhone">
                            Relative Phone Number
                          </label>
                          <input
                            type="text"
                            maxLength={10}
                            minLength={10}
                            placeholder="Relative Phone Number"
                            disabled={getValues()?.relatives[index]?.relativePhone}
                            {...register(`relatives.${index}.relativePhone`)}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register(`relatives.${index}.relativePhone`, {
                              required: false,
                              pattern: {
                                value: /^\d+$/,
                                message: "Mobile number must be number.",
                              },
                              minLength: {
                                value: 10,
                                message:
                                  "Phone number must be minimum 10 digit",
                              },
                            })}
                          ></input>
                          {errors.relativePhone?.type === "required" && (
                            <small className="text-danger">
                              {" "}
                              Number is required{" "}
                            </small>
                          )}
                          {errors.relativePhone && (
                            <span className="error">
                              {errors.relativePhone.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {
                        !getValues()?.relatives[index]?.relativeId && (
                          <div className="col-lg-1 mb-4 mb-lg-0">
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="form-close-btn"
                            >
                              <MdClose />
                            </button>
                          </div>
                        )
                      }
                    </div>
                  );
                })}

                <div className="col-lg-12 col-md-6">
                  <div className="form-part">
                    <label htmlFor="name">
                      Would you like to add a relative?
                    </label>
                    <div className="d-flex align-items-centre">
                      <button
                        type="button"
                        className="custom-btn-cls add-relative-btn"
                        onClick={() => {
                          append({
                            relativeName: "",
                            relativeRelation: "",
                            relativeImpDate: "",
                            relativeAddress: "",
                            relativePhone: "",
                            relativePin: "",
                          });
                        }}
                      >
                        Add Relative
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                    ref={recaptchaRef}
                    size="invisible"
                  />
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  {/* <button className="custom-btn-cls ms-0">Submit</button> */}
                  <button className="custom-btn-cls ms-0" disabled={isSubmitting}>
                    {
                      isSubmitting &&
                      <div class="spinner-border me-2" role="status">                      </div>
                    }
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <GoToTop top={top} />
    </>
  );
};

export default DmsJoinFamily;
