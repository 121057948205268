import React, { useEffect, useState } from 'react';
import { Link, useNavigate, NavLink } from "react-router-dom";
import ApiService from "../api/ApiService";
import { Modal, ModalTitle } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import notification from "../helpers/notification";
import moment from "moment/moment";
import PageHeader from "../components/common/PageHeader";
import "../css/brithdaySlot.css";

// import '../src/App.css';

const Lantern = () => {



  const navigate = useNavigate();

  const [phonenum, setPhonenum] = useState();
  const [loading, setLoading] = useState(false);

  const scrollToElement = () => {
    const element = document.getElementById('scrollToAllPages');
    //console.log("elements",element);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  };



  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();




  const Login = async (LoginData) => {
    //return console.log(LoginData)
    try {

      setLoading(true)
      let res = await ApiService.lanternLogin(LoginData);

      if (res.message === "You don't have access to this resource.") {
         notification("error", "You don't have access to this resource.");
         reset();
        //console.log(res, res.birthdaytoken)
        // localStorage.setItem(`birthday_token`, res.birthdaytoken);
        // localStorage.setItem(`birthday_phone`, res.phone);
        // localStorage.setItem(`birthday_name`, res.name);
        // localStorage.setItem(`birthday_bookby`, res.bookby);

        //navigate('/brithday_slot')
      } else {
        notification("success", "OTP sent successfully.");
        setLoading(false)
        setPhonenum(LoginData?.phone);
        reset({
            otp:''
         });
       
      }
      console.log("resrs", res.message);

    } catch (error) {
      console.error("Error Login data:", error);

    }
  };


//console.log(phonenum)

  const   Verify = async (verifyotp) => {
    try {

      setLoading(true)

        let AllData = {
            phone:phonenum,
            ...verifyotp
        }
      
      let res = await ApiService.verifyLogin(AllData);
       console.log("resrs", res.message);

      if (res.message === "OTP verified.") {
         notification("success", "You are logged in");
         setLoading(false)
         navigate('/donations')
         setPhonenum('')
         reset();
        //console.log(res, res.birthdaytoken)
         localStorage.setItem(`lantern_token`, res.token);
        // localStorage.setItem(`birthday_phone`, res.phone);
        // localStorage.setItem(`birthday_name`, res.name);
        // localStorage.setItem(`birthday_bookby`, res.bookby);

        //navigate('/brithday_slot')
      } else {
        notification("error", "invalid OTP");
        reset();
       
      }
      

    } catch (error) {
      console.error("Error Login data:", error);

    }
  };




  // const handleViewButtonClick = async () => {

  //     navigate('/view_slot');
  // };





  useEffect(() => {
    scrollToElement();
  }, [])


  // useEffect(() => {
  //     fetchBookedSDateWiselots(selectedDate);
  // }, [selectedDate]);

  return (
    <>
      <PageHeader title="Login Page" imgSrc="/images/page-header/13.webp" />
      <section className="connect-heading-sec" style={{ padding: '7px 0' }}>
        <div className="container" >
          <div className="row" id="scrollToAllPages">
           
          </div>
        </div>
      </section>
      <section className="contact-wrap bg-white" style={{ padding: '14px 0' }}>
        <div className="container">
          <div className="row d-flex justify-content-center mb-5">
          
            <div className=" col-lg-6 order-0 order-lg-1 " >
              <div className="contact-form bg-white p-3" style={{ border: '1px solid black', borderRadius: '10px' }} >
                <div className="form-title mb-5">
                <h4 className="head-1" style={{ fontSize: '21px' }}>LANTERN LOGIN</h4>
                </div>
                {phonenum ? 
                
                <form
                  className="order-0 order-lg-1"
                  onSubmit={handleSubmit(Verify)}
                >
                  <div className="row align-items-center">
                    <div className="col-sm-12">
                      <div className="input-wrap">
                        <label htmlFor="firstname" className="imp">
                         Enter OTP
                        </label>
                        <input
                          type="tel"
                          id="otp"
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          minLength={6}
                          maxLength={6}
                          placeholder="Enter OTP"
                          {...register("otp", {
                            required: true,
                          })}
                        />
                      </div>
                    </div>
                    

                    <div className="col-12 mt-3">
                      <button className="box-hover custom-btn" type="submit">
                        verify OTP &nbsp;{loading ?  <div className='spinner-border text-light'  role='status'></div> :''}            
                      </button>
                    </div>
                  </div>
                </form>
                
                :
                <form
                  className="order-0 order-lg-1"
                  onSubmit={handleSubmit(Login)}
                >
                  <div className="row align-items-center">
                    <div className="col-sm-12">
                      <div className="input-wrap">
                        <label htmlFor="firstname" className="imp">
                          Phone
                        </label>
                        <input
                          type="tel"
                          id="phone"
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("phone", {
                            required: true,
                            pattern: {
                              value: /^\d+$/,
                              message: "Mobile number must be number.",
                            },
                            minLength: {
                              value: 10,
                              message: "Phone number must be minimum 10 digit",
                            },
                          })}
                        />
                      </div>
                    </div>
                   

                    <div className="col-12 mt-3">
                      <button className="box-hover custom-btn" type="submit">

                        Login &nbsp;{loading ?  <div className='spinner-border text-light'  role='status'></div> :''}                                
                                            
                      </button>
                      
                    </div>
                  </div>
                </form>}

                
              </div>
            </div>
            
          </div>
        </div>
      </section>
      {/* <div style={styles.container}>
        <h1 style={{ textAlign: "center" }}>Birthday Login </h1>
        <form
          action=""
          className="mt-3 mt-sm-0"
          onSubmit={handleSubmit(birthdayLogin)}
        >
          <div className='textarea'>
            <label htmlFor="phone" className="imp">Mobile Number</label>
            <div className="label-input-wrap">

              <input
                type="tel"
                id="phone"
                maxLength={10}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                {...register("phone", {
                  required: true,
                  pattern: {
                    value: /^\d+$/,
                    message: "Mobile number must be number.",
                  },
                  minLength: {
                    value: 10,
                    message: "Phone number must be minimum 10 digit",
                  },
                })}
              />
            </div>
            <label htmlFor="password" className="imp">Password</label>
            <div className="label-input-wrap textarea">

              <input
                type="password"
                id="password"
                {...register("password", { required: true })}
                onKeyPress={(event) => {
                  if (/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {errors.password?.type === "required" && (
                <span>  Password is required </span>
              )}
            </div>
          </div>

          <button className="box-hover custom-btn" style={{ marginTop: '5px' }} type="submit">Login</button>
        </form>
      </div> */}
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    marginTop: "200px",
  },
  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: "100%",
    boxSizing: "border-box",
  },

};

export default Lantern;